import React from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

export default function Breadcrumb({ crumbs, className }) {
  const { t } = useTranslation("common");

  return (
    crumbs && (
      <nav aria-label={t("breadcrumb.title")} className={className}>
        <ol className="flex flex-wrap items-center mb-6">
          {crumbs.map((crumb, index) => {
            const isFirst = index === 0;
            const isLast = index === crumbs.length - 1;
            return (
              <li
                key={`crumb-${crumb.title}`}
                className="mb-0.5 font-important font-medium tracking-wider uppercase text-xs"
              >
                {!isFirst && (
                  <span
                    className="inline-block px-4 separator"
                    aria-hidden="true"
                  >
                    /
                  </span>
                )}
                {!isLast && crumb.path ? (
                  <Link
                    href={crumb.translate ? t(crumb.path) : crumb.path}
                    passHref
                  >
                    <a className="underline underline-offset-4 decoration-transparent hover:decoration-white transition-colors">
                      {crumb.translate ? t(crumb.title) : crumb.title}
                    </a>
                  </Link>
                ) : (
                  <span className="text-opacity-80">
                    {crumb.translate ? t(crumb.title) : crumb.title}
                  </span>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    )
  );
}
