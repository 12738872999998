import React from "react";
import Breadcrumb from "components/Breadcrumb";
import classNames from "lib/classNames";

export default function Header({
  crumbs,
  title,
  subtitle,
  variant = "primary",
}) {
  const variants = {
    primary: "bg-primary-dark text-white",
    secondary: "bg-secondary text-white",
    tertiary: "bg-tertiary text-white",
  };

  return (
    <section
      className={classNames(
        "relative pt-12 pb-28 overflow-hidden",
        variants[variant]
      )}
    >
      <div
        className="absolute left-1/2 top-16 text-[210px] leading-none text-default opacity-10 font-important font-bold whitespace-nowrap"
        aria-hidden="true"
      >
        {title}
      </div>
      <div className="relative container mx-auto px-4">
        <Breadcrumb crumbs={crumbs} className="mb-16" />

        <h1 className="flex-1 text-4xl lg:text-6xl font-important font-bold">
          {title}
        </h1>

        {subtitle && (
          <div className="flex justify-center lg:justify-start items-center mt-16">
            {typeof subtitle === "string" ? (
              <>
                <span
                  className="inline-block h-2.5 w-2.5 mr-4 border-2 border-primary rounded-full"
                  aria-hidden="true"
                />
                <p>{subtitle}</p>
              </>
            ) : (
              subtitle
            )}
          </div>
        )}
      </div>
    </section>
  );
}
